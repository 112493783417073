<template>
  <div>
    <div class="d-flex justify-content-end">
      <base-button text="Cetak" variant="outline-primary" @click="print" />
    </div>
    <div id="page-a4" class="mt-3">
      <div class="header">
        <div class="kop-surat row no-gutters">
          <div
            class="col-md-2 d-flex justify-content-center align-items-center"
          >
            <img
              :src="require('@/assets/img/logo/logo.svg')"
              alt="Company Logo"
              width="50"
              height="50"
            />
          </div>
          <div class="col-md-10">
            <h2 class="fs-4 m-0">PT JASTEL MEDIKA UTAMA</h2>
            <address class="fs-6 mb-1">
              Cingcin, Kec. Soreang, Kabupaten Bandung, Jawa Barat 40921<br />
              Tlp: 081312856201<br />
              Email: RQGpH@example.com
            </address>
          </div>
        </div>
        <hr class="mb-0" style="border-top: 2px solid #ccc" />
        <hr class="mt-1" style="border-top: 4px solid #ccc" />
      </div>
      <div class="content">
        <table class="ml-3 table-sm">
          <tr>
            <td>Mobil Unit</td>
            <td>: {{ muName }}</td>
          </tr>
          <tr>
            <td>Tanggal</td>
            <td>: {{ date }}</td>
          </tr>
        </table>

        <p class="w-75 mx-auto text-right mt-5">
          Total diterima:
          {{ items.filter((item) => item.blood_received === 1).length }}
        </p>

        <b-skeleton-table
          v-if="isLoading === true"
          :rows="5"
          :columns="5"
          class="w-75 mx-auto"
        ></b-skeleton-table>

        <table class="w-75 table-sm table border mx-auto">
          <tr>
            <th>No</th>
            <th>Barcode</th>
            <th>Nomor Selang</th>
            <th>Gol. Darah</th>
            <th>Jenis Kantong</th>
          </tr>
          <tr
            v-for="(item, index) in items.filter(
              (item) => item.blood_received === 1
            )"
            :key="item.id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ item.barcode }}</td>
            <td>{{ item.blood_bag_number }}</td>
            <td>
              {{ item.blood_type.replace("+", " Pos").replace("-", " Neg") }}
            </td>
            <td>{{ item.bag_type }}</td>
          </tr>
        </table>
      </div>

      <div class="footer d-flex justify-content-around">
        <div class="text-center">
          <p class="mb-5">Pengirim</p>
          (..........................................)
        </div>
        <div class="text-center">
          <p class="mb-5">Penerima</p>
          (..........................................)
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSkeletonTable } from "bootstrap-vue";
import manageBloodShipmentAPI from "../../../../../api/blood_shipment/manageBloodShipmentAPI";
import html2pdf from "html2pdf.js";

export default {
  name: "PrintBloodReceived",
  components: {
    BSkeletonTable,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      muName: "",
      date: "",
    };
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        let response;

        if (this.$route.path.includes("/lab/processing/blood-received/print")) {
          response = await manageBloodShipmentAPI.getDeliveryProcessing({
            date: this.$route.params.date,
            mu_name: this.$route.params.mu,
          });
        } else if (this.$route.path.includes("/lab/KGD/blood-received/print")) {
          response = await manageBloodShipmentAPI.getDeliveryKGD({
            date: this.$route.params.date,
            mu_name: this.$route.params.mu,
          });
        } else if (
          this.$route.path.includes("/lab/IMLTD/blood-received/print")
        ) {
          response = await manageBloodShipmentAPI.getDeliveryIMLTD({
            date: this.$route.params.date,
            mu_name: this.$route.params.mu,
          });
        } else {
          console.error(
            "Invalid route: No matching API found for the given path."
          );
        }

        if (response.data && response.data.success) {
          this.items = response.data.data;
          if (this.items.length > 0) {
            this.muName = this.items[0].mu_name;
            this.date = this.items[0].date;
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.isLoading = false;
      }
    },

    print() {
      const opt = {
        margin: 0,
        filename: `Laporan Penerimaan Darah ${this.muName}_${this.date}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: "mm",
          format: [210, 297], // A4 size in mm
          orientation: "portrait",
        },
      };

      html2pdf().from(document.querySelector("#page-a4")).set(opt).save();
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
#page-a4 {
  width: 210mm;
  height: 290mm;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  background-color: white;
}

.header {
  margin-bottom: 20px;
}

.content {
  margin-bottom: 100px;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 7rem;
}
</style>
